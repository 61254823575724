

/* eslint-disable no-param-reassign */
import {
    WIGETS_FORM_SET,
    WIGETS_FORM_UPDATE,
    WIGETS_FORM_REARRANGE,
    WIGETS_FORM_GET,
    SAVE_CARD,
    UPDATE_CARD,
    GET_CARD,
    SET_WIDGETS_FORM_DATA,
    SET_USERPROFILE_LINK,
    SET_PROFILE_WIDGET,
    SET_USERNAME_EXIST_ERROR,
    GET_CARD_BY_USERNAME,
    SET_PROFILE_THEME,
    UPDATE_COMPANY_CARD,
    SET_PREVIEW_RIGHT_SIDEBAR,
    SET_LOADING,
    SET_PREVIEW_RIGHT_SIDEBAR_SUCCESS,
    SET_LOADING_ACTION,
    SET_LOADING_PREVIEW_WIDGET,
    GET_ALL_WIDGET,
    GET_ALL_WIDGET_SUCCESS,
    GET_ALL_WIDGET_ERROR,
    UPDATE_WIDGET,UPDATE_WIDGET_SUCCESS,UPDATE_WIDGET_ERROR,
    CREATE_WIDGET,CREATE_WIDGET_SUCCESS,CREATE_WIDGET_ERROR, DELETE_CARD
  
  
  } from "../actions";
  
  export const addWidgetsFormData = (widgetsFormData) => {
    return {
      type: WIGETS_FORM_SET,
      payload: widgetsFormData,
    };
  };
  
  export const updateWidgetsFormData = (widgetsFormData, index) => {
    return {
      type: WIGETS_FORM_UPDATE,
      payload: { data: widgetsFormData, index },
    };
  };
  

  
  export const saveCard = (id, card) => {
    return {
      type: SAVE_CARD,
      payload: { id, card }
    }
   
  }
  export const deleteCard = (id, card) => {
    return {
      type: DELETE_CARD,
      payload: { id, card }
    }
   
  }
  
  export const updateCard = (id, card) => ({
    type: UPDATE_CARD,
    payload: { id, card },
  });
  
  export const updateCompanyCard = (id, card) => ({
    type: UPDATE_COMPANY_CARD,
    payload: { id, card },
  });
  
  export const getCard = (id) => ({
    type: GET_CARD,
    payload: { id },
  });
  
  export const getCardByUsername = (id) => ({
    type: GET_CARD_BY_USERNAME,
    payload: { id },
  });
  
  export const setWidgetFormsData = (card) => ({
    type: SET_WIDGETS_FORM_DATA,
    payload: { card },
  });
  
  export const setWidgetFormLoading = (loading) => ({
    type: SET_LOADING,
    payload: loading,
  });
  export const setWidgetFormLoadingAction = (loading) => ({
    type: SET_LOADING_ACTION,
    payload: loading,
  });
  export const setPreviewWidgetLoading = (loading) => ({
    type: SET_LOADING_PREVIEW_WIDGET,
    payload: loading,
  });
  
  export const setProfileLink = (card) => ({
    type: SET_USERPROFILE_LINK,
    payload: { card },
  });
  
  export const setProfileTheme = (card) => ({
    type: SET_PROFILE_THEME,
    payload: { card },
  });
  
  export const setUsernameExistError = (error) => ({
    type: SET_USERNAME_EXIST_ERROR,
    payload: error,
  });
  
  export const reArrangeWidgetsFormData = (widgetsFormData) => {
    return {
      type: WIGETS_FORM_REARRANGE,
      payload: widgetsFormData,
    };
  };
  
  export const showProfileWidget = (widgetsFormData) => {
    return {
      type: SET_PROFILE_WIDGET,
      payload: widgetsFormData,
    };
  };
  
  export const setPreviewSideBar = (show) => {
    return {
      type: SET_PREVIEW_RIGHT_SIDEBAR,
      payload: { show },
    };
  };
  export const setPreviewSideBarSuccess = (show) => {
    return {
      type: SET_PREVIEW_RIGHT_SIDEBAR_SUCCESS,
      payload:  show ,
    };
  }
  
  
  
  // export const getAllWidgetSuccess = (widgets) => ({
  // 	type: GET_ALL_WIDGET_SUCCESS,
  // 	payload:  widgets
  // });
  
  
  
  
  
  
  
  
  
