import {
	GET_ANALYTICS_GRAPHICAL,
	GET_ANALYTICS_GRAPHICAL_SUCCESS,
	GET_ANALYTICS_GRAPHICAL_ERROR,
	GET_ANALYTICS_LISTING,
	GET_ANALYTICS_LISTING_SUCCESS,
	GET_ANALYTICS_LISTING_ERROR,
	DOWNLOAD_ANALYTICS,DOWNLOAD_ANALYTICS_SUCCESS,DOWNLOAD_ANALYTICS_ERROR,
	SAVE_ANALYTICS,
	SAVE_ANALYTICS_SUCCESS,
	SAVE_ANALYTICS_ERROR,
	CLEAR_TEAM_SUCCESS,
} from "../actions";

export const getAnalyticListing = (data) => ({
	type: GET_ANALYTICS_LISTING,
	payload: data,
});

export const getAnalyticListingSuccess = (analytic) => ({
	type: GET_ANALYTICS_LISTING_SUCCESS,
	payload: analytic.results,
});

export const getAnalyticListingError = (message) => ({
	type: GET_ANALYTICS_LISTING_ERROR,
	payload: { message },
});
export const getAnalyticGraphical = (data) => ({
	type: GET_ANALYTICS_GRAPHICAL,
	payload: data,
});

export const getAnalyticGraphicalSuccess = (analytic) => ({
	type: GET_ANALYTICS_GRAPHICAL_SUCCESS,
	payload: analytic,
});

export const getAnalyticGraphicalError = (message) => ({
	type: GET_ANALYTICS_GRAPHICAL_ERROR,
	payload: { message },
});

export const saveAnalytic = (analytic) => ({
	type: SAVE_ANALYTICS,
	payload: { analytic },
});

export const saveAnalyticSuccess = (message) => ({
	type: SAVE_ANALYTICS_SUCCESS,
	payload: {message},
});

export const saveAnalyticError = (message) => ({
	type: SAVE_ANALYTICS_ERROR,
	payload: { message },
});

export const clearTeamSuccess = () => ({
	type: CLEAR_TEAM_SUCCESS,
});


export const downloadExcelAnalytics = (data) => ({
	type: DOWNLOAD_ANALYTICS,
	payload:  data ,
});

export const downloadExcelAnalyticsSuccess = (analyics) => ({
	type: DOWNLOAD_ANALYTICS_SUCCESS,
	payload: analyics,
});

export const downloadExcelAnalyticsError = (message) => ({
	type: DOWNLOAD_ANALYTICS_ERROR,
	payload: { message },
});