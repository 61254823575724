import {
  LOGIN_USER,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SIGNUP_USER,
  SIGNUP_USER_ERROR,
  SIGNUP_USER_SUCCESS,
  SET_USER_INFO,
  GET_USER_INFO,
  GET_USER_INFO_RESET_CODE,
  LOGIN_WITH_GMAIL,
  RESEND_VERIFICATION,
  RESEND_VERIFICATION_SUCCESS,
  RESEND_VERIFICATION_ERROR,
  DESTROY_SESSION,
  ADD_DEPARTMENT_ADMIN_DAT_IN_USER,
  EMAIL_VERIFICATION,
  EMAIL_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_ERROR,
  CLEAR_AUTH_SUCCESS,
  UPDATE_USER_INFO,
} from "../actions";

export const loginUser = (user, history, redirectToCheckout) => ({
  type: LOGIN_USER,
  payload: { user, history, redirectToCheckout },
});

export const loginWithGmail = (user, history) => ({
  type: LOGIN_WITH_GMAIL,
  payload: { user, history },
});

export const signupUser = (user, history) => ({
  type: SIGNUP_USER,
  payload: { user, history },
});

export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const updateUserInfo = (user) => ({
  type: UPDATE_USER_INFO,
  payload: { user },
});

export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const getUserInfo = () => ({
  type: GET_USER_INFO,
  payload: {},
});
export const getUserInfoResetCode = () => ({
  type: GET_USER_INFO_RESET_CODE,
  payload: {},
});

export const setUserProfile = (user) => ({
  type: SET_USER_INFO,
  payload: user,
});

export const signupUserSuccess = (user) => ({
  type: SIGNUP_USER_SUCCESS,
  payload: user,
});

export const signupUserError = (message) => ({
  type: SIGNUP_USER_ERROR,
  payload: { message },
});

export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history },
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail,
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message },
});

export const verifyYourEmail = (id, history) => ({
  type: EMAIL_VERIFICATION,
  payload: { id, history },
});

export const verifyYourEmailSuccess = (message) => ({
  type: EMAIL_VERIFICATION_SUCCESS,
  payload: message,
});

export const verifyYourEmailError = (message) => ({
  type: EMAIL_VERIFICATION_ERROR,
  payload: message,
});

export const resendVerification = (resendVerificationMail, history) => ({
  type: RESEND_VERIFICATION,
  payload: { resendVerificationMail, history },
});
export const resendVerificationSuccess = (resendVerificationMail) => ({
  type: RESEND_VERIFICATION_SUCCESS,
  payload: resendVerificationMail,
});
export const resendVerificationError = (message) => ({
  type: RESEND_VERIFICATION_ERROR,
  payload: { message },
});

export const resetPassword = ({ resetPasswordCode, newPassword, history }) => ({
  type: RESET_PASSWORD,
  payload: { resetPasswordCode, newPassword, history },
});
export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword,
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message },
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});

export const addDAloginDataInUser = (department) => ({
  type: ADD_DEPARTMENT_ADMIN_DAT_IN_USER,
  payload: { department },
});
export const onClear = () => {
  return { type: DESTROY_SESSION };
};

export const clearAuthSuccess = () => {
  return {
    type: CLEAR_AUTH_SUCCESS,
  };
};
