import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import widgetSagas from './widgetsForm/saga';
import templatesSagas from './templates/saga';
import employeesSagas from './employees/saga';
import companiesSagas from './companies/saga';
 



import analyticsSagas from './analytics/saga';
import exchangedContactsSagas from './exchangedContacts/saga';

export default function* rootSaga() {

  yield all([
    authSagas(),
    widgetSagas(),
    templatesSagas(),
    employeesSagas(),
    companiesSagas(),
    analyticsSagas(),
    exchangedContactsSagas(),
  ]);
}
