import React from 'react'

export default function Logout() {
  return (
    
    <svg xmlns="http://www.w3.org/2000/svg" width="18.5" height="18.5" viewBox="0 0 18.5 18.5">
        <g id="fi-rr-sign-out_1" data-name="fi-rr-sign-out 1" transform="translate(0.25 0.25)">
        <path id="Vector" d="M5.25,16.5H3.75A2.25,2.25,0,0,1,1.5,14.25V3.75A2.25,2.25,0,0,1,3.75,1.5h1.5a.75.75,0,0,0,0-1.5H3.75A3.755,3.755,0,0,0,0,3.75v10.5A3.754,3.754,0,0,0,3.75,18h1.5a.75.75,0,1,0,0-1.5Z" transform="translate(0 0)" fill="#a2a2c2" stroke="#a2a2c2" stroke-width="0.5"/>
        <path id="Vector-2" data-name="Vector" d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z" transform="translate(3.75 3.759)" fill="#a2a2c2" stroke="#a2a2c2" stroke-width="0.5"/>
        </g>
    </svg>

  )
}
