import axios from "axios";
import { servicePath } from "constants/defaultValues";

const instance = axios.create({
  baseURL: servicePath,
});



instance.interceptors.request.use(
  async (config) => {
    const token = JSON.parse(localStorage.getItem("token"))
    // const token = localStorage.getItem("token").accessToken

    if (token) {
      config.headers = { ...config.headers, Authorization: `Bearer ${token.accessToken}` };
    }
    return config;
  },
  (err) => Promise.reject(err)
);

export default instance;

