import {
  GET_ANALYTICS_LISTING,
  GET_ANALYTICS_LISTING_SUCCESS,
  GET_ANALYTICS_LISTING_ERROR,
  GET_ANALYTICS_GRAPHICAL,
  GET_ANALYTICS_GRAPHICAL_SUCCESS,
  GET_ANALYTICS_GRAPHICAL_ERROR,
  SAVE_ANALYTICS,
  SAVE_ANALYTICS_SUCCESS,
  SAVE_ANALYTICS_ERROR,
  CLEAR_TEAM_SUCCESS,
} from "../actions";

const INIT_STATE = {
  analytic: {},
  analyticLisiting: [],
  analyticgraphLoader: false,
  analyticListLoader: false,

  loading: false,
  error: "",
  success: "",
  modalSuccess: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ANALYTICS_GRAPHICAL:
      return { ...state, loading: true, analyticLoader: true, error: "" };
    case GET_ANALYTICS_GRAPHICAL_SUCCESS:
      return {
        ...state,
        loading: false,
        analytic: action.payload,
        error: "",
        analyticLoader: false
      };
    case GET_ANALYTICS_GRAPHICAL_ERROR:
      return {
        ...state,
        loading: false,
        analytic: {},
        error: action.payload.message,
        analyticLoader: false

      };

    case GET_ANALYTICS_LISTING:
      return { ...state, loading: true, analyticListLoader: true, error: "" };
    case GET_ANALYTICS_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        analyticLisiting: action.payload,
        error: "",
        analyticListLoader: false

      };
    case GET_ANALYTICS_LISTING_ERROR:
      return {
        ...state,
        loading: false,
        analyticLisiting: [],
        error: action.payload.message,
        analyticListLoader: false

      };

    case SAVE_ANALYTICS:
      return { ...state, loading: true, error: "", modalSuccess: "" };
    case SAVE_ANALYTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        analytic: action.payload,
        error: "",
        modalSuccess: action.payload.message,
      };
    case SAVE_ANALYTICS_ERROR:
      return {
        ...state,
        loading: false,
        analytic: {},
        error: action.payload.message,
        modalSuccess: "",
      };

    case CLEAR_TEAM_SUCCESS:
      return { ...state, loading: false, error: "", success: "" };

    default:
      return { ...state };
  }
};
