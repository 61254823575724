import { combineReducers } from 'redux';

import authUser from './auth/reducer';
import widgetsForm from './widgetsForm/reducer';
import templatesData from './templates/reduces';
import employeesData from './employees/reduces';
import companiesData from './companies/reduces';

import analyticsData from './analytics/reduces';



import exchangedContactsData from './exchangedContacts/reduces';

const reducers = combineReducers({
    authUser,
    widgetsForm,
    employeesData,
    templatesData,
    companiesData,
    analyticsData,
    exchangedContactsData,
});
const rootreducer = (state, action) => {
    if (action.type === 'LOGOUT_USER') return reducers(undefined, action);
    return reducers(state, action);
};


export default rootreducer;
