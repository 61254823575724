export const defualtWidgetTheme = "BasicTheme";
export const widgetThemeStorageKey = "__oval_theme_selected_color";
export const appRoot = "/app";
export const loginPage = "/user/login";
export const servicePath = "https://oval-api-prod.herokuapp.com/api";
export const appUrl = "https://oval-api-prod.herokuapp.com";
export const baseAppUrl = "https://ovalapp.bio";
// export const servicePath = 'http://localhost:8800/api';
// export const appUrl = 'https://localhost:8800';
// export const baseAppUrl = 'http://localhost:3000';

export const STRIPE_PUBLISH =
  "pk_live_51LYMjuAjN1ano4Ii8GP4wMDd8gCmazqkxLaZiGOjOpPseD8hICaWWWPC8VwN9ICZRqrP8sd7I7pXNjaDdrWKRBfM0091OAwNrq";

// export const STRIPE_PUBLISH =
//   'pk_test_51LD3NfJLRaTwwMUyNBgM7DlCKmKrbhpp7FQtUlcoKD8J531L8gMessjyWQJZ06aTBOfqhpdVHO0V9YDLB8eaIy3T00c0RiM8OM';

// export const STRIPE_PUBLISH =
//   'pk_test_qblFNYngBkEdjEZ16jxxoWSM';

export const currentUser = {};
