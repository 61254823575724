import {
  GET_EMPLOYEES,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_ERROR,
  GET_COMPANY_EMPLOYEE,
  GET_COMPANY_EMPLOYEE_SUCCESS,
  GET_COMPANY_EMPLOYEE_ERROR,
  GET_FILTERATION_DATA_EMPLOYEE_BY_USERID,
  GET_FILTERATION_DATA_EMPLOYEE_BY_USERID_SUCCESS,
  GET_FILTERATION_DATA_EMPLOYEE_BY_USERID_ERROR,
  SAVE_EMPLOYEE,
  SAVE_EMPLOYEE_SUCCESS,
  SAVE_EMPLOYEE_ERROR,
  GET_EMPLOYEE,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_ERROR,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_ERROR,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_ERROR,
  CLEAR_TEAM_SUCCESS,
  GET_ALL_EMPLOYEES,
  GET_ALL_EMPLOYEES_SUCCESS,
  GET_ALL_EMPLOYEES_ERROR,
  CREATE_EMPLOYEE_AUDIT_LOG,
  CREATE_EMPLOYEE_AUDIT_LOG_SUCCESS,
  CREATE_EMPLOYEE_AUDIT_LOG_ERROR,
  GET_FILTERATION_DATA_EMPLOYEE,
  GET_FILTERATION_DATA_EMPLOYEE_SUCCESS,
  GET_FILTERATION_DATA_EMPLOYEE_ERROR,
  RESET_FILTERATION_DATA,
  RESET_FILTERATION_DATA_SUCCESS,
  LOGOUT_USER,
} from "../actions";

const INIT_STATE = {
  employees: [],
  employee: {},
  filterData: [],
  secondLoading: false,
  loadingData: {},
  loading: false,
  employeeLoading: false,

  error: "",
  success: "",
  modalSuccess: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_EMPLOYEES:
      return { ...state, employeeLoading: true, error: "" };
    case GET_ALL_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employees: action.payload,
        error: "",
        employeeLoading: false,
      };
    case GET_ALL_EMPLOYEES_ERROR:
      return {
        ...state,
        loading: false,
        employees: [],
        error: action.payload.message,
        employeeLoading: false,
      };
    case GET_EMPLOYEES:
      return { ...state, loading: true, employeeLoading: true, error: "" };
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        employees: action.payload,
        error: "",
        employeeLoading: false,
      };
    case GET_EMPLOYEES_ERROR:
      return {
        ...state,
        loading: false,
        employees: [],
        error: action.payload.message,
        employeeLoading: false,
      };

    case GET_COMPANY_EMPLOYEE:
      return { ...state, loading: true, employeeLoading: true, error: "" };

    case GET_COMPANY_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        employees: action.payload,
        error: "",
        employeeLoading: false,
      };

    case GET_COMPANY_EMPLOYEE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        employeeLoading: false,
      };

    case SAVE_EMPLOYEE:
      return { ...state, loading: true, error: "", success: "" };
    case SAVE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        employee: action.payload,
        error: "",
        success: action.payload.message,
      };
    case SAVE_EMPLOYEE_ERROR:
      return {
        ...state,
        loading: false,
        employee: {},
        error: action.payload.message,
        success: "",
      };

    case GET_EMPLOYEE:
      return { ...state, loading: true, employeeLoading: true, error: "" };
    case GET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        employee: action.payload,
        error: "",
        employeeLoading: false,
      };
    case GET_EMPLOYEE_ERROR:
      return {
        ...state,
        loading: false,
        // employee: {},
        error: action.payload.message,
        employeeLoading: false,
      };

    case UPDATE_EMPLOYEE:
      return { ...state, loading: true, error: "", success: "" };
    case UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload.message,
      };
    case UPDATE_EMPLOYEE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: "",
      };

    case DELETE_EMPLOYEE:
      return {
        ...state,
        secondLoading: true,
        loadingData: action.payload,
        error: "",
      };
    case DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        employees: state.employees?.filter((el) => el.id !== action.payload.id),
        error: "",
        loadingData: {},
        secondLoading: false,
      };
    case DELETE_EMPLOYEE_ERROR:
      return {
        ...state,
        loading: false,

        // employee: {},
        error: action.payload.message,
        loadingData: {},
        secondLoading: false,
      };
    case GET_FILTERATION_DATA_EMPLOYEE:
      return { ...state, loading: true, employeeLoading: true, error: "" };
    case GET_FILTERATION_DATA_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        filterData: action.payload,
        error: "",
        employeeLoading: false,
      };

    case GET_FILTERATION_DATA_EMPLOYEE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        employeeLoading: false,
      };
    case GET_FILTERATION_DATA_EMPLOYEE_BY_USERID:
      return { ...state, loading: true, employeeLoading: true, error: "" };
    case GET_FILTERATION_DATA_EMPLOYEE_BY_USERID_SUCCESS:
      return {
        ...state,
        loading: false,
        employee: action.payload,
        error: "",
        employeeLoading: false,
      };

    case GET_FILTERATION_DATA_EMPLOYEE_BY_USERID_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        employeeLoading: false,
      };

     
        case RESET_FILTERATION_DATA:
      return { ...state, loading: true, error: "" };
    case RESET_FILTERATION_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        filterData: action.payload,
        error: "",
      };

    case CLEAR_TEAM_SUCCESS:
      return { ...state, loading: false, error: "", success: "" };

    default:
      return { ...state };
  }
};
