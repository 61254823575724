import {
 
  GET_COMPANY,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_ERROR,
  GET_COMPANY_BY_ID,
  GET_COMPANY_BY_ID_SUCCESS,
  GET_COMPANY_BY_ID_ERROR,
  GET_FILTERATION_COMPANY_OWNER_BY_USERID,
  GET_FILTERATION_COMPANY_OWNER_BY_USERID_SUCCESS,
  GET_FILTERATION_COMPANY_OWNER_BY_USERID_ERROR,

  SAVE_COMPANY,
  SAVE_COMPANY_SUCCESS,
  SAVE_COMPANY_ERROR,
  SAVE_COMPANY_BY_SA,
	SAVE_COMPANY_BY_SA_SUCCESS,
	SAVE_COMPANY_BY_SA_ERROR,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_ERROR,
  GET_ALL_COMPANIES,
  GET_ALL_COMPANIES_SUCCESS,
  GET_ALL_COMPANIES_ERROR,
  CLEAR_TEAM_SUCCESS,
  CLEAR_COMPANY_SUCCESS,
  LOGOUT_USER,
  SET_COMPANY_SELECTED

} from "../actions";

const INIT_STATE = {
  company: {},
  companies:[],

  loading: false,
  error: "",
  companyError: "",
  companyLoading:false,

  success: "",
  modalSuccess: "",
  selectedCompanyBySA:{},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_ALL_COMPANIES:
      return { ...state, companyLoading: true,companyError:"" };
    case GET_ALL_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload,
        companyLoading: false,
        companyError: "",

        
      };
    case GET_ALL_COMPANIES_ERROR:
      return {
        ...state,
        loading: false,
        companyLoading: false,
        companyError: action.payload.message,

      };

    case GET_COMPANY:
      return { ...state, loading: true, companyError:"" };
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        company: action.payload,
        companyError:""
        
      };
    case GET_COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        company: {},
        companyError: action.payload.message,

      };

    case GET_COMPANY_BY_ID:
      return { ...state, loading: true,companyError: "" };
    case GET_COMPANY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        company: action.payload,
        companyError: ""
      };
    case GET_COMPANY_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        company: {},
        companyError: action.payload.message
      };

    case SAVE_COMPANY:
      return { ...state, loading: true, error: "", companyError: "", modalSuccess: "" };
    case SAVE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        company: action.payload,
        error: "",
        modalSuccess: action.payload.message,
        companyError: ""
      };
    case SAVE_COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        company: {},
        error: action.payload.message,
        modalSuccess: "",
        // companyError: action.payload.message
      };
      case SAVE_COMPANY_BY_SA:
        return { ...state, loading: true, error: "", companyError: "", modalSuccess: "" };
      case SAVE_COMPANY_BY_SA_SUCCESS:
        return {
          ...state,
          loading: false,
          // company: action.payload,
          error: "",
          modalSuccess: action.payload.message,
          success:action.payload.message,
          companyError: ""
        };
      case SAVE_COMPANY_BY_SA_ERROR:
        return {
          ...state,
          loading: false,
          company: {},
          error: action.payload.message,
          modalSuccess: "",
          // companyError: action.payload.message
        };
    case UPDATE_COMPANY:
      return { ...state, loading: true, error: "", success: "" };
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.payload.message,
      };
    case UPDATE_COMPANY_ERROR:

      return {
        ...state,
        loading: false,
        company: {},
        error: action.payload.message,
        success: "",
      };
      case GET_FILTERATION_COMPANY_OWNER_BY_USERID:
        return { ...state, 
          companyLoading: true, companyError: "" };
      case GET_FILTERATION_COMPANY_OWNER_BY_USERID_SUCCESS:
        return {
          ...state,
          loading: false,
          companies: action.payload,
          companyError: "",
          companyLoading: false,
        };
  
      case GET_FILTERATION_COMPANY_OWNER_BY_USERID_ERROR:
        return {
          ...state,
          companyError:action.payload.message,
          error: action.payload.message,
          companies:[],
          companyLoading: false,
        };
    
    
    case CLEAR_COMPANY_SUCCESS:
      return { ...state, loading: false, error: "",companyError: "", success: "" };
    
      case SET_COMPANY_SELECTED:
        return { ...state,selectedCompanyBySA:action.payload , error: "", success: "" };
    default:
      return { ...state };
  }
};
