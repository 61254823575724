
import {
    WIGETS_FORM_SET,
    WIGETS_FORM_REARRANGE,
    WIGETS_FORM_GET,
    SET_LOADING_PREVIEW_WIDGET,
    SET_WIDGETS_FORM_DATA,
    WIGETS_FORM_UPDATE,
    SET_USERPROFILE_LINK,
    SET_PROFILE_WIDGET,
    SET_USERNAME_EXIST_ERROR,
    SET_PROFILE_THEME,
    SET_PREVIEW_RIGHT_SIDEBAR,
    SET_LOADING,
    SET_PREVIEW_RIGHT_SIDEBAR_SUCCESS,
    LOGOUT_USER,
    SET_LOADING_ACTION,
    
  } from "../actions";
  
  const INIT_STATE = {
    widgetsFormData: [],
    userProfileLink: "",
    userProfileTheme: "",
    setProfileWidget: false,
    usernameExistError: true,
    showPreviewSideBar: false,
    loading: true,
    widgetsFormLoader:false,
    // allWidgets:[],
    error: "",
    previewWidgetLoader:false
  };
  
  export default (state = INIT_STATE, action) => {
    // console.log(action)
    switch (action.type) {
     
      case SET_LOADING:
        return {
          ...state,
          loading: action.payload,
        };
        case SET_LOADING_ACTION:
          return {
            ...state,
            widgetsFormLoader: action.payload,
          };
          case SET_LOADING_PREVIEW_WIDGET:
            return {
              ...state,
              previewWidgetLoader: action.payload,
            };
      case WIGETS_FORM_UPDATE:
        const array = [...state.widgetsFormData];
        array[action.payload.index] = action.payload.data;
  
  
        return {
          ...state,
          widgetsFormData: array,
        };
      case WIGETS_FORM_REARRANGE:
        return {
          ...state,
          widgetsFormData: action.payload,
        };
      case SET_WIDGETS_FORM_DATA:
        return {
          ...state,
          widgetsFormData: action.payload.card,
        };
      case SET_USERPROFILE_LINK:
        return {
          ...state,
          userProfileLink: action.payload.card,
        };
      case SET_PROFILE_THEME:
        return {
          ...state,
          userProfileTheme: action.payload.card,
        };
      case SET_USERNAME_EXIST_ERROR:
        return {
          ...state,
          usernameExistError: action.payload,
        };
      case SET_PROFILE_WIDGET:
        return {
          ...state,
          setProfileWidget: action.payload,
        };
  
      case SET_PREVIEW_RIGHT_SIDEBAR:
        return {
          ...state,
          loading: true,
        };
      case SET_PREVIEW_RIGHT_SIDEBAR_SUCCESS:
        return {
          ...state,
          showPreviewSideBar: action.payload,
        };
     
      default:
        return { ...state };
    }
  };
  